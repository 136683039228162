.modal {
  // --bs-modal-zindex: 1055;
  // --bs-modal-width: 500px;
  --bs-modal-padding: 0rem;
  // --bs-modal-margin: 0.5rem;
  // --bs-modal-color: ;
  // --bs-modal-bg: var(--bs-body-bg);
  // --bs-modal-border-color: var(--bs-border-color-translucent);
  // --bs-modal-border-width: var(--bs-border-width);
  // --bs-modal-border-radius: var(--bs-border-radius-lg);
  // --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  // --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  // --bs-modal-header-padding-x: 1rem;
  // --bs-modal-header-padding-y: 1rem;
  // --bs-modal-header-border-color: var(--bs-border-color);
  // --bs-modal-header-border-width: var(--bs-border-width);
  // --bs-modal-title-line-height: 1.5;
  // --bs-modal-footer-gap: 0.5rem;
  // --bs-modal-footer-bg: ;
  // --bs-modal-footer-border-color: var(--bs-border-color);
  // --bs-modal-footer-border-width: var(--bs-border-width);

  --bs-modal-header-padding: 1.2rem 2rem;
  --bs-modal-padding: 0;
}

.modal {
  transition: all 0.4s ease-out !important;

  :global(.modal-content) {
    background-color: white;
  }

  :global(.modal-footer.inside-tab) {
    align-self: flex-start;
    position: sticky;
    bottom: 0;
    // width: calc(100% + var(--bs-modal-header-padding-x) + var(--bs-modal-header-padding-x));
    // margin-left: calc(var(--bs-modal-header-padding-x) * -1);
    width: calc(100% + 3.95rem);
    margin-left: -2rem;
    border: 0 !important;
    z-index: var(--bs-modal-zindex);
    // background-color: white;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
    // background-color: var(--bs-secondary);
  }

  // :global(.modal-container-sm),
  // :global(.modal-container) {
  //   z-index: 9999990000000000;
  //   position: relative;

  //   >::after {
  //     content: " ";
  //     width: 100%;
  //     height: 20px;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     background: var(--bs-primary);
  //     background: rebeccapurple;
  //     // margin-top: -20px;
  //     z-index: 999999;

  //   }
  // }

  :global(.modal-container) {
    padding: 2rem 2rem 0 2rem;
    box-shadow: 0 -5px 5px -2px rgba(0, 0, 0, 0.04);
    min-height: 300px;
  }

  :global(.modal-container-sm) {
    padding: 2rem 2rem 0 2rem;
    box-shadow: 0 -5px 5px -2px rgba(0, 0, 0, 0.04);
    min-height: 100px;
  }

  :global(.tab-content) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    :global(.stretched-container) {
      > div:first-child {
        padding: 2rem 2rem 0 2rem;
      }
    }

    :global(.stretched-container .inside-tab) {
      width: calc(100% + var(--bs-modal-header-padding-x));
    }
  }

  :global(.tab-gradient) {
    background: var(--bs-secondary);
    padding: 0 2rem 0.1rem 2rem;
    border: none;
  }

  :global(.modal-header) {
    background: var(--bs-secondary);
    border: 0;
  }

  :global(.modal-footer) {
    margin-top: 0rem;
    padding: 1rem 1.8rem 1.5rem 1.8rem;
    border: 0;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
    // background-color: var(--bs-secondary);

    // :global(.btn),
    // button {
    //   box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2) !important;
    // }
  }

  @media (min-width: 576px) and (max-width: 899px) {
    &:global(.modal-lg) {
      max-width: 95% !important;
    }
  }

  @media (max-width: 576px) {
    // &:global(.modal-lg) {
    //   width: 95% !important;
    //   max-width: 95% !important;
    // }
  }
}

.content {
  border-radius: var(--bs-border-radius-xl) !important;
  border: 0 !important;
  min-height: 300px;
  max-height: calc(100% - 150px) !important;
  // height: 60%;
  // height: 540px;
  // max-height: 540px;

  &:global(.sm) {
    // height: auto;
    min-height: auto;
  }

  &:global(.sm) {
    // height: auto;
    min-height: auto;
  }

  &:global(.login) {
    // height: 100%;
    max-height: 600px;
  }

  &:global(.h-400) {
    height: 400px;
    max-height: 400px;
  }

  &:global(.h-540) {
    height: 540px;
    max-height: 540px;
  }

  &:global(.h-600) {
    height: 600px;
    max-height: 600px;
  }

  &:global(.h-720) {
    height: 720px;
    max-height: 720px;
  }
}

.navDropdown {
  :global(.dropdown-item.active) {
    background-color: var(--bs-gray-600);
  }

  :global(.dropdown-item:active) {
    color: var(--bs-gray-600);
    background-color: var(--bs-gray-200);
  }

  :global(.dropdown-item.active) {
    background-color: var(--bs-gray-600);
  }
}
